import { render, staticRenderFns } from "./CreatedLoanDetail.vue?vue&type=template&id=067ff5f8&"
import script from "./CreatedLoanDetail.vue?vue&type=script&lang=js&"
export * from "./CreatedLoanDetail.vue?vue&type=script&lang=js&"
import style0 from "./CreatedLoanDetail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports