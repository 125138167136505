<template>
    <b-overlay :show="loading" rounded="sm">
        <main class="my-4 bg-light p-4">
            <div class="row mb-4">
                <div class="col-8">
                    <b-card v-if="mambuLoan" class="mb-4 h-100">
                        <div class="d-flex justify-content-between align-items-center">
                            <b-card-title>Loan Information</b-card-title>
                            <div class="text-muted d-flex align-items-center">
                                {{ mambuLoan.accountState }}
                                <feather type="info" class="feather-sm text-success ml-2"></feather>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <h6 class="mb-0">Loan Id</h6>
                                <p class="text-muted">{{ mambuLoan.id }}</p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">Loan Amount</h6>
                                <p class="text-muted">AWG {{ mambuLoan.loanAmount }}</p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">Loan Name</h6>
                                <p class="text-muted">{{ mambuLoan.loanName }}</p>
                            </div>
                        </div>

                        <div class="row" v-if="customer">
                            <div class="col-4">
                                <h6 class="mb-0">Customer Name</h6>
                                <p class="text-muted">{{ customer.firstName }} {{ customer.lastName }}</p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">Address</h6>
                                <p class="text-muted">{{ customer.address1 }}</p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">Date of birth</h6>
                                <p class="text-muted">
                                    {{ $formatDate(customer.birthDate) }}
                                </p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">Email</h6>
                                <p class="text-muted mb-0">{{ customer.emailAddress }}</p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">State</h6>
                                <p class="text-muted mb-0">{{ customer.state }}</p>
                            </div>
                            <div class="col-4">
                                <h6 class="mb-0">Application ID</h6>
                                <a
                                    v-if="application"
                                    :href="`/applications/list/${application.applicantInfo.id}?loanApplicationId=${application.id}`"
                                    target="_blank"
                                    class="mt-4"
                                    style="text-decoration: underline"
                                    >{{ application.applicationCode }}</a
                                >

                                <p v-else class="text-muted">N/A</p>
                            </div>
                        </div>
                    </b-card>
                </div>

                <div class="col-4">
                    <b-card v-if="mambuLoan">
                        <b-card-title>Timeline</b-card-title>
                        <div class="timeline block mb-6">
                            <div class="tl-item">
                                <div class="tl-dot border-primary"></div>
                                <div class="tl-content">
                                    <h6>Creation Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 120px">
                                        {{ $formatDate(mambuLoan.creationDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item">
                                <div class="tl-dot border-primary"></div>
                                <div class="tl-content">
                                    <h6>Approved Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 120px">
                                        {{ $formatDate(mambuLoan.approvedDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item">
                                <div class="tl-dot border-primary"></div>
                                <div class="tl-content">
                                    <h6>Disbursement Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 125px">
                                        {{ $formatDate(mambuLoan.disbursementDetails.disbursementDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item" v-if="mambuLoan.closedDate">
                                <div class="tl-dot border-danger"></div>
                                <div class="tl-content">
                                    <h6>Closed Date</h6>
                                    <div class="tl-date text-muted mt-1" style="max-width: 120px">
                                        {{ $formatDate(mambuLoan.closedDate) }}
                                    </div>
                                </div>
                            </div>
                            <div class="tl-item">
                                <div class="tl-content"></div>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-4">
                    <b-card v-if="mambuLoan && mambuLoan.disbursementDetails" class="mb-4 h-100">
                        <b-card-title> Disbursement Information </b-card-title>
                        <div class="mb-3">
                            <h6 class="mb-0">Amount</h6>
                            <ItemText :text="mambuLoan.loanAmount" isCurrency />
                        </div>
                        <div class="mb-3">
                            <h6 class="mb-0">Expected date</h6>
                            <!-- <p class="text-muted mb-0">{{ mambuLoan.disbursementDetails.expectedDisbursementDate }}</p> -->
                            <ItemText :text="$formatDate(mambuLoan.disbursementDetails.expectedDisbursementDat)" />
                        </div>
                        <div>
                            <h6 class="mb-0">Disbursement Date</h6>
                            <!-- <p class="text-muted mb-0">
                                {{ mambuLoan.disbursementDetails.disbursementDate }}
                            </p> -->
                            <ItemText :text="$formatDate(mambuLoan.disbursementDetails.disbursementDate)" />
                        </div>
                        <!-- <br /> -->
                        <!-- <div class="row">
                        <div class="col-4">
                            <h6 class="mb-0">Interest Amount</h6>
                            <p class="text-muted mb-0">AWG {{ disbursement.AffectedAmounts.InterestAmount }}</p>
                        </div>
                        <div class="col-4">
                            <h6 class="mb-0">Fees Amount</h6>
                            <p class="text-muted mb-0">AWG {{ disbursement.AffectedAmounts.FeesAmount }}</p>
                        </div>
                        <div class="col-4">
                            <h6 class="mb-0">Principal Amount</h6>
                            <p class="text-muted mb-0">AWG {{ disbursement.AffectedAmounts.PrincipalAmount }}</p>
                        </div>
                    </div> -->
                    </b-card>
                </div>
                <div :class="mambuLoan && mambuLoan.disbursementDetails ? 'col-8' : 'col-12'">
                    <b-card>
                        <b-card-title>Schedule</b-card-title>
                        <b-table
                            style="min-height: 200px; max-height: 240px; overflow-y: scroll"
                            show-empty
                            responsive
                            class="mb-0 mt-2"
                            :filter="null"
                            :items="schedules"
                            :fields="fields"
                            hover
                            borderless
                            striped
                        >
                            <template #cell(dueDate)="data"> {{ $formatDate(data.item.dueDate) }}</template>
                            <template #cell(payment)="data"> AWG {{ data.item.principal.amount.expected }}</template>
                            <template #cell(interest)="data"> AWG {{ data.item.interest.amount.expected }}</template>
                        </b-table>
                    </b-card>
                </div>
            </div>

            <b-card>
                <h4 class="text-center mb-4">Actions</h4>
                <div class="row">
                    <div class="col-3 d-flex justify-content-center border-right">
                        <b-button
                            variant="primary"
                            class="d-flex align-items-center justify-content-center"
                            @click="updateStateMambu"
                            :disabled="!isPending"
                        >
                            <b-spinner small v-if="loadingState"></b-spinner>
                            <feather v-else type="user-check" class="feather-sm mr-2"></feather>
                            Approve Loan
                        </b-button>
                    </div>
                    <div class="col-3 d-flex justify-content-center border-right">
                        <b-button
                            :variant="'primary'"
                            class="d-flex align-items-center justify-content-center"
                            :disabled="!isApproved"
                            v-b-modal.modal-transferred-funds
                        >
                            <feather type="dollar-sign" class="feather-sm mr-2"></feather>
                            Transfer Funds
                        </b-button>
                    </div>
                    <div class="col-3 d-flex justify-content-center border-right">
                        <b-button
                            class="d-flex align-items-center justify-content-center"
                            variant="primary"
                            v-b-modal.modal-reschedule
                            :disabled="!isActive || hasLoanApplication"
                        >
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            <feather v-else type="edit" class="feather-sm mr-2"></feather>
                            Reschedule
                        </b-button>
                    </div>
                    <div class="col-3 d-flex justify-content-center">
                        <b-button
                            class="d-flex align-items-center justify-content-center"
                            variant="primary"
                            v-b-modal.modal-refinance
                            :disabled="!isActive || hasLoanApplication"
                        >
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            <feather v-else type="credit-card" class="feather-sm mr-2"></feather>
                            Refinance
                        </b-button>
                    </div>
                </div>
            </b-card>

            <b-modal id="modal-refinance" hide-footer centered title="Refinance" size="sm">
                <form @submit.prevent="handleAction('refinance')">
                    <div>
                        <div>
                            <label for="LoanAmount" style="font-weight: 700">Loan Amount</label>
                            <b-input
                                type="number"
                                class="WhitePlaceHolder"
                                id="LoanAmount"
                                name="LoanAmount"
                                v-model="amount"
                                v-bind:min="minAmount"
                                v-bind:max="maxAmount"
                                v-on:input="Debounce()"
                                placeholder=""
                                required
                            />
                        </div>
                        <div>
                            <label for="DDLPeriod" style="font-weight: 700">Period in Months</label>
                            <b-form-select
                                v-model="monthSelected"
                                class="mb-3"
                                id="DDLPeriod"
                                name="DDLPeriod"
                                required
                            >
                                <b-form-select-option
                                    v-for="Month in monthsArray"
                                    :key="Month"
                                    :value="Month"
                                    required
                                    >{{ Month }}</b-form-select-option
                                >
                            </b-form-select>
                        </div>
                        <div>
                            <label for="noteRefinance" style="font-weight: 700">Note</label>
                            <b-form-textarea
                                id="noteRefinance"
                                v-model="note"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                                required
                            ></b-form-textarea>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-preview-schedule')"
                            >Cancel</b-button
                        >
                        <b-button variant="primary" type="submit">
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            Refinance
                        </b-button>
                    </div>
                </form>
            </b-modal>

            <b-modal id="modal-reschedule" hide-footer centered title="Reschedule" size="sm">
                <form @submit.prevent="handleAction('reschedule')">
                    <div>
                        <div>
                            <label for="DDLPeriod" style="font-weight: 700">Period in Months</label>
                            <b-form-select
                                v-model="monthSelected"
                                class="mb-3"
                                id="DDLPeriod"
                                name="DDLPeriod"
                                required
                            >
                                <b-form-select-option
                                    v-for="Month in monthsRescheduleArray"
                                    :key="Month"
                                    :value="Month"
                                    required
                                    >{{ Month }}</b-form-select-option
                                >
                            </b-form-select>
                        </div>
                        <div>
                            <label for="noteRefinance" style="font-weight: 700">Note</label>
                            <b-form-textarea
                                id="noteRefinance"
                                v-model="note"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                                required
                            ></b-form-textarea>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-preview-schedule')"
                            >Cancel</b-button
                        >
                        <b-button variant="primary" type="submit">
                            <b-spinner small v-if="loadingAction"></b-spinner>
                            Reschedule
                        </b-button>
                    </div>
                </form>
            </b-modal>

            <b-modal id="modal-transferred-funds" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to transfer funds? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal-transferred-funds')"
                        >No</b-button
                    >
                    <b-button
                        variant="primary"
                        @click="updateStatusFunds('TransferredFunds', !process.transferredFunds)"
                    >
                        <b-spinner small v-if="loadingFunds"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>
        </main>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import ItemText from '../../components/ItemText.vue';

export default {
    name: 'CreatedLoanDetail',
    components: { ItemText },

    data() {
        return {
            loan: null,
            createdLoan: null,
            schedules: [],
            disbursement: null,
            loading: false,
            loadingRefinance: false,
            loadingReschedule: false,
            loadingAction: false,
            maritalStatusArray: [
                { id: 1, name: 'Casa' },
                { id: 2, name: 'Soltero/-a' },
                { id: 3, name: 'Biba Hunto' },
                { id: 4, name: 'Otro' },
            ],
            fields: [
                { key: 'number', label: 'Number', sortable: true },
                { key: 'payment', label: 'Expected Payment', sortable: true },
                { key: 'interest', label: 'Expected Interest', sortable: true },
                { key: 'state', label: 'State', sortable: true },
                { key: 'dueDate', label: 'Due Date', sortable: true },
            ],
            amount: null,
            monthSelected: null,
            monthsArray: [],
            monthsRescheduleArray: [],
            minAmount: null,
            maxAmount: null,
            debounceTimer: null,
            note: null,
            mambuLoan: null,
            loadingState: false,
            loadingFunds: false,
            customer: null,
            application: null,
            loans: [],
            hasLoanApplication: true,
        };
    },

    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            process: 'customer/productRequest/getProcess',
        }),
        isPending() {
            return this.mambuLoan?.accountState === 'PENDING_APPROVAL';
        },
        isApproved() {
            return (
                this.mambuLoan?.accountState === 'APPROVED' ||
                (this.mambuLoan?.accountState === 'ACTIVE' && this.mambuLoan?.accountSubState === 'PARTIALLY_DISBURSED')
            );
        },
        isActive() {
            return this.mambuLoan?.accountState === 'ACTIVE';
        },
        maritalStatus() {
            return this.maritalStatusArray.find((el) => el.id === this.customer?.additionalDetails?.maritalStatusId);
        },
    },

    methods: {
        async getData() {
            try {
                this.loading = true;
                await this.getMambuLoan();
                await this.getCustomerData();
                await this.getLoanApplication();
                await this.getSourceLoans();
                this.getLoanRange();
                this.getMonthsReschedule();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loading = false;
            }
        },

        async getSourceLoans() {
            const response = await this.$store.dispatch(
                'loan/applications/get_loanBySourceLoanId',
                this.$route.params.loan,
            );
            this.hasLoanApplication = response.statusResult;
        },

        async getMambuLoan() {
            const response = await this.$store.dispatch('mambu/get_mambuLoanByLoanId', this.$route.params.loan);
            this.mambuLoan = response.data.dataResult?.payload;
            const scheduleData = await this.$store.dispatch('mambu/get_mambuSchedule', this.$route.params.loan);
            this.schedules = scheduleData.data.dataResult.payload.installments;
        },

        async getCustomerData() {
            const response = await this.$store.dispatch(
                'mambu/get_customerByEncodedKey',
                this.mambuLoan.accountHolderKey,
            );
            this.customer = response?.payload;
        },

        async getLoanApplication() {
            const response = await this.$store.dispatch('loan/applications/get_loanApplicationById', 1);
            this.application = response?.dataResult;
        },

        async getLoanRange() {
            const loanTypeId = this.application?.loanTypeId ?? 1;
            const res = await this.$store.dispatch('loan/applications/get_loanRange', loanTypeId);
            this.minAmount = res.minAmount;
            this.maxAmount = res.maxAmount;
        },

        async getMonthsReschedule() {
            const loanTypeId = this.application?.loanTypeId ?? 1;
            const response = await axios.get(
                `loans/Api/LoanApplications/GetMonthsByLoanType?Amount=${this.mambuLoan.loanAmount}&LoanTypeId=${loanTypeId}`,
            );
            if (response.status === 200) {
                this.monthsRescheduleArray = response.data;
            } else {
                console.error('Error fetching reschedule months.');
            }
        },

        async fetchMonths() {
            const loanTypeId = this.application?.loanTypeId ?? 1;
            const response = await axios.get(
                `loans/Api/LoanApplications/GetMonthsByLoanType?Amount=${this.amount}&LoanTypeId=${loanTypeId}`,
            );
            if (response.status === 200) {
                this.monthsArray = response.data;
                this.monthSelected = response.data[0];
            } else {
                console.error('Error fetching months.');
            }
        },

        async updateStatusFunds(service, status) {
            const res = await this.$store.dispatch('mambu/make_disbursement', this.$route.params.loan);
            if (res) {
                if (this.status != null && this.serviceSelected != null) this.loadingFunds = true;
                await this.$store.dispatch(`productRequest/update_status${service}`, {
                    id: this.$route.params.customer,
                    status: status,
                });
                await this.getData();
                this.loadingFunds = false;
                this.$bvModal.hide('modal-transferred-funds');
            }
        },
        Debounce() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(async () => {
                if (this.amount >= this.minAmount && this.amount <= this.maxAmount) {
                    await this.fetchMonths();
                } else {
                    this.showToast(`Please enter a valid amount between ${this.minAmount} and ${this.maxAmount}`);
                }
            }, 1500);
        },

        showToast(message) {
            this.$bvToast.toast(message, {
                title: 'Attention!',
                variant: 'warning',
                solid: true,
            });
        },
        async updateStateMambu() {
            this.loadingState = true;
            await this.$store.dispatch('mambu/update_mambuState', this.$route.params.loan);
            await this.getData();
            this.loadingState = false;
        },

        async handleAction(actionType) {
            this.loadingAction = true;
            const payload = {
                loanApplicationId: this.application.id,
                loanId: this.$route.params.loan,
                periodInMonths: this.monthSelected,
                loanAmount: this.amount,
            };

            console.log('payload', payload);
            console.log('actionType', actionType);

            if (actionType === 'refinance') {
                this.loadingRefinance = true;
                await this.$store.dispatch('loan/applications/refinance_loanApplication', payload);
            } else {
                this.loadingReschedule = true;
                await this.$store.dispatch('loan/applications/reschedule_loanApplication', payload);
            }

            await this.getData();
            this.loadingAction = false;

            this.$bvModal.hide('modal-refinance');
            this.$bvModal.hide('modal-reschedule');
            this.clearData();
        },

        clearData() {
            this.amount = null;
            this.monthSelected = null;
            this.note = null;
        },
    },

    async created() {
        await this.getData();
    },
};
</script>

<style>
.tl-item .avatar {
    z-index: 2;
}

.circle {
    border-radius: 500px;
}

.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.timeline {
    position: relative;
    padding: 0;
    margin: 0;
}

.p-4 {
    padding: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
}

.tl-item > * {
    padding: 10px;
}

.tl-item .avatar {
    z-index: 2;
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

.tl-content p:last-child {
    margin-bottom: 0;
}

.tl-date {
    font-size: 0.85em;
    margin-top: 2px;
    min-width: 100px;
    max-width: 100px;
}

.avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.b-warning {
    border-color: #f4c414 !important;
}

.b-primary {
    border-color: #448bff !important;
}

.b-danger {
    border-color: #f54394 !important;
}
</style>
